<template>
  
    <banner :banner="banner" :caption="caption" :path="bannerPath"></banner>

    <section class="content-home border-bottom-red">
        <div class="container-fluid">
            <div class="row">
                <div class="col-2">
                    <div class="content-block">
                        <img class="icon-red img-left" src="../../assets/images/visi_misi.png" alt="icon">
                    </div>
                </div>

                <div class="col-10">
                    <div class="content-block mt-3"> 
                        <h3>Visi & Misi <span class="alternate">Bahtera Adhiguna</span></h3>

                        <div class="row">
                            <div class="col-lg-6 mb-5">
                                <div class="visi-wrap ml-5 mt-5">
                                    <div class="visi-item">
                                        <img src="../../assets/images/biru.png" class="img-visi" />
                                    </div>
                                    
                                    <div class="visi-item item-visi-text">
                                        <h4 class="text-blue">VISI KAMI</h4>
                                        <p class="text-muted ml-3">Menjadi Perusahaan angkutan batubara dan gas serta penunjangnya yang unggul dan terpercaya dengan pelayanan berstandarinternasional.</p>
                                    </div>
                                </div>
                                
                            </div>

                            <div class="col-lg-6 mt-5">
                                <div class="visi-wrap">
                                    <div class="visi-item">
                                        <img src="../../assets/images/merah.png" class="img-misi">
                                    </div>

                                    <div class="visi-item item-visi-text">
                                        <h4 class="text-red">MISI KAMI</h4>
                                        <ol>
                                            <li>
                                                <p class="text-muted">
                                                    Menyelengarakan usaha transportasi laut dan penunjangnya untuk pengamanan pasokan batubara dan gas untuk pembangkit listrik PLN, anak Perusahaan PLN, dan IPP.
                                                </p>
                                            </li>

                                            <li>
                                                <p class="text-muted">
                                                    Menyelenggarakan sistem manajemen berdasarkan prinsip tatakelola perusahaan yang sehat(GCG).
                                                </p>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h4 class="text-blue mb-3">PENJELASAN TERKAIT VISI ADALAH SEBAGAI BERIKUT:</h4>

                        <ol>

                            <li class="list-penjelasan">
                                <h6 class="text-red">Unggul</h6>
                                <p class="text-muted">Perusahaan menjadi yang terbaik dan terkemuka dalam bisnis angkutan batubara dan gas, menggunakan teknologi mutakhir yang didukung dengan armada yang handal, fokus dalam usaha, mengoptimalkan potensi insani, serta mampu meningkatkan kualitas input, proses, dan outputjasa pelayanan secara berkesinambungan.</p>
                            </li>

                            <li class="list-penjelasan">
                                <h6 class="text-red">Terpercaya</h6>
                                <p class="text-muted">Perusahaan mampu memegang teguh etika bisnis, konsisten memenuhi standar layanan yang dijanjikan, memuaskan para stakeholder, serta menjadi perusahaan favorit para pihak yang berkepentingan.</p>
                            </li>

                            <li class="list-penjelasan">
                                <h6 class="text-red">Standar Internasional</h6>
                                <p class="text-muted">Memenuhi item-item persyaratan standar yang ditetapkan oleh The International Safety Management (ISM) Code. ISM Code adalah standar internasional manajemen keselamatan dalam pengoperasian kapal serta upaya pencegahan/pengendalian pencemaran lingkungan. ISM Code berisikan 13 item penilaian dan secara best practice suatu perusahaan transportasi harus memenuhi semua itemtersebut.</p>
                            </li>

                        </ol>

                        <div class="flex-container">
                            <div class="flex-item bg-blue">
                                <div class="d-flex justify-content-center align-items-center mt-3">
                                    <router-link to="/tentang_kami">
                                        <img src="../../assets/images/sejarah.png" width="50" height="50" alt="icon">
                                        <p class="text-white">SEJARAH</p>
                                    </router-link>
                                </div>
                            </div>

                            <div class="flex-item bg-red">
                                <div class="d-flex justify-content-center align-items-center mt-3">
                                        <router-link to="/visi_misi">
                                            <img src="../../assets/images/visi_misi.png" width="50" height="50" alt="icon">
                                            <p class="text-white">VISI - MISI</p>
                                        </router-link>
                                </div>
                            </div>

                            <div class="flex-item bg-blue">
                                <div class="d-flex justify-content-center align-items-center mt-3">
                                    <router-link to="/budaya">
                                        <img src="../../assets/images/budaya.png" width="50" height="50" alt="icon">
                                        <p class="text-white">BUDAYA</p>
                                    </router-link>
                                </div>
                            </div>

                            <div class="flex-item bg-blue">
                                <div class="d-flex justify-content-center align-items-center mt-3">
                                    <router-link to="/manajemen">
                                        <img src="../../assets/images/manajemen.png" width="50" height="50" alt="icon">
                                        <p class="text-white">MANAJEMEN</p>
                                    </router-link>
                                </div>
                            </div>

                            <div class="flex-item bg-blue">
                                <div class="d-flex justify-content-center align-items-center mt-3">
                                    <router-link to="/tata_kelola">
                                        <img src="../../assets/images/tata_kelola.png" width="50" height="50" alt="icon">
                                        <p class="text-white">TATA KELOLA</p>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>

import Banner from '../banner/banner.vue'
import axios from 'axios'

export default {
    name: 'pageVisiMisi',
    components: {
        Banner
    },
    data () {
        return {
            // bannerPath: 'http://localhost:8080/assets/banner/',
            bannerPath: 'https://api.plnbag.co.id/assets/banner/',
            banner: '',
            caption: '',
        }
    },
    mounted () {
        this.loadBanner()
    },
    methods: {
        loadBanner () {
            axios.get('visi_misi_banner')
                .then((res) => {
                    this.banner = res.data.data.banner
                    this.caption = res.data.data.caption_indo
                })
        }
    }
}
</script>

<style scoped>
    .img-left {
        width: 85px;
        height: auto;
    }

    @media screen and (max-width: 767px) {
        .img-left {
            width: 45px;
            height: auto;
        }
    }

    .icon-red {
        background-color: #ea2e23;
        border-radius: 5px;
        padding: 10px;
        float: right;
    }

    @media (max-width: 767px) {
        .icon-red {
            float: none;
        }
    }

    .border-bottom-red {
        border-bottom: 5px solid #ea2e23;
    }

    .content-home {
        position: relative;
    }

    .content-home .content-block {
        padding: 25px 0 0;
    }

    .content-home .content-block h3 {
        color: #0158a7;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .content-home .content-block span.title-w {
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        margin-left: 10px;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .content-home .content-block p.text-w {
        color: #fff;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    }

    .content-home .content-block p {
        color: #404041;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    }

    .content-home .image-block {
        position: absolute;
        right: 5%;
        bottom: 0;
    }

    @media (max-width: 767px) {
        .content-home .image-block {
            display: none;
        }
    }

    span.alternate {
        color: #ea2e23;
        font-weight: 800;
    }

    .visi-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .visi-wrap div.visi-item {
        flex: 0 0 300px;
    }

    .visi-wrap div.visi-item img.img-visi {
        width: 300px;
        height: auto;
        z-index: -1;
    }

    .item-visi-text {
        margin-left: -230px;
        margin-top: -185px;
    }

    .visi-wrap div.visi-item p {
        font-size: 17px;
        text-align: left;
    }

    .text-blue {
        color: #0158a7;
    }

    @media screen and (max-width: 767px) {
        .visi-wrap div.visi-item {
            flex: 0 0 200px;
        }

        .visi-wrap div.visi-item img.img-visi {
            width: 200px;
            height: 350px;
            margin-left: -100px;
            margin-top: 30px;
        }

        .visi-wrap div.visi-item p {
            font-size: 15px;
            text-align: left;
        }
    }

    .visi-wrap div.visi-item img.img-misi {
        width: 200px;
        height: auto;
        margin-top: -60px;
        margin-left: -140px;
    }

    .text-red {
        color: #ea2e23;
    }

    @media screen and (max-width: 767px) {
        .visi-wrap div.visi-item img.img-misi {
            height: 300px;
            width: 80px;
            margin-top: -90px;
        }

        .visi-wrap div.visi-item h4.text-red {
            margin-top: 120px;
        }
    }

    ol li.list-penjelasan {
        list-style-type: none;
    }



    /* CSS untuk kotak-kotak di bawah */

    .img-bottom-link {
        width: 50px;
        height: auto;
    }

    .flex-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .flex-container div.flex-item {
        flex: 0 0 180px;
        margin: 2px 2px;
    }

    .bg-blue {
        background-color: #0158a7;
    }

    .bg-red {
        background-color: #ea2e23;
    }

    .flex-container div.flex-item div.d-flex a {
        text-decoration: none;
    }

</style>